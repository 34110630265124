
import meta from './meta.json';
import logger  from 'logger';
const devConfig = {
    backendURL : "http://localhost:5000",
    //backendURL: "https://annotator-be.kemtai.com",

    imageURLprefix: "https://annotator.kemtai.com/",
    googleClientId: "450020363607-oqq2e99m96r2n50eot1kd4nipre8prvq.apps.googleusercontent.com",
    version: meta.version
}

const prodConfig = {
    backendURL: "https://annotator-be.kemtai.com",
    imageURLprefix: "https://annotator.kemtai.com/",
    googleClientId: "450020363607-oqq2e99m96r2n50eot1kd4nipre8prvq.apps.googleusercontent.com",
    version: meta.version
}

//console.log("NODE_ENV:", process.env.NODE_ENV,process.env)

logger.init({app: "web-annotator", version: meta.version, url: "https://api.kemtai.com/log/"})
let config = (process.env.NODE_ENV === "development") ? devConfig : prodConfig
export default config;

