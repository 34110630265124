import React, { Component } from 'react';
import backend from "../backend";
import { observable, makeObservable } from "mobx";
import { observer } from "mobx-react";


import DataTable from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { Box } from '@mui/material';


type AdminDataSetsState = {
    ver : string
}

class AdminDataSets extends Component<{},AdminDataSetsState> {
    data : any;
    value : number  = 1;
    loading : boolean = true

    constructor(props:{}) {
        super(props)

        this.state = {
            ver:"v5"
        }
        makeObservable(this, {
            data: observable,
            value: observable,
            loading: observable,
        });
    

    }


    componentDidMount(){
        this.loadData()     
    }

    loadData = async () => {
        this.loading = true
        this.data = await backend.dataset_stats(this.state.ver)
        this.loading = false

    }
    handleSelect = async (event:any) => {
        console.log("handleSelect:",event.target.value)

        await this.setState({ver:event.target.value})
        this.loadData()
    }

    render() {       
        return (
            <Box>
                        Show: 
            <select value={this.state.ver} onChange={this.handleSelect}> 
                <option value="all"> all</option>
                <option value="v5"> v5</option>
                <option value="hands"> hands</option>
                <option value="v113"> v113</option>

                <option value="v4"> v4</option>
                <option value="v3"> v3</option>
                <option value="v2"> v2</option>
                <option value="v1"> v1</option>
                <option value="newpoints"> newpoints</option>
                <option value="v111"> v111</option>
                <option value="tasq"> tasq</option>
                <option value="v75"> v75</option>
                <option value="v5baby"> v5baby</option>
                <option value="v5bootcamp"> v5bootcamp</option>


            </select> datasets

           
            <DataTable                        
                progressPending={this.loading}
                data={this.data?.datasets} 
                keyField="did" 
                striped highlightOnHover defaultSortField="did" defaultSortAsc={false}
            columns={[
               {name: 'ID',  selector: 'did',  sortable: true, width: "80px"},
               {name: 'ver',  selector: 'ver',  sortable: true, width: "80px"},
               {name: 'private', selector: 'private',  sortable: true, width: "80px"},
               {name: 'hidden',  selector: 'hidden',  sortable: true, width: "80px"},
               {name: 'priority',  selector: 'priority',  sortable: true, width: "80px"},

               {name: 'Dataset', selector: 'name', sortable: true },
               /*{name: '# Frames', selector: 'num_frames', sortable: true },*/
               {name: '# Frames', sortable: true , selector:'num_frames',
                cell: (row:any) => {
                        return <Link to={`/ann?did=${row.did}`}> {row.num_frames} </Link>
                   }
                },
               {name: '# Annotated', selector: 'annotated', sortable: true,
                 cell: (row:any) => {
                    return <Link to={`/ann?did=${row.did}&annotated=y`}> {row.annotated} </Link>
                 }
                } ,
               /*
               {
                 name: 'Browse', width: "80px",
                 cell: (row:any) => <AiOutlineMonitor onClick={()=>manager.qcmode(`did=${row.did}`)} data-tip="browse"/>,
               }*/
       ]}/>
        </Box>

)
    }
}

export default observer(AdminDataSets);